import React from "react";
import "./modal.css";
import PropTypes from "prop-types";

export default class Modal extends React.Component {
    onClose = e => {
        this.props.onClose && this.props.onClose(e);
    };

    render() {
        if (!this.props.show) {
            return null;
        }
        return (
            <div class="modal" id="modal">
                <h2>ยินดีด้วย</h2>
                <div>{this.props.bonus}</div>
                <div class="content">{this.props.children}</div>
                    <button class="toggle-button" onClick={this.onClose}>
                        X
                    </button>
            </div>
        );
    }
}

Modal.propTypes = {
    onClose: PropTypes.func.isRequired,
    show: PropTypes.bool.isRequired,
    bouns: PropTypes.number.isRequired
};