import { lazy } from 'react'
import Draw from '../../view/draw/draw'
import Login from '../../view/login/login'



const AppRoutes = [
    {
        path: '/login',
        component: Login
    },
    {
        path: '/deaw',
        component: Draw
    }

]

export default AppRoutes
