import Draw from './draw';

const DrawFrom = () => {
  return (
    <div >
      <Draw />
    </div>
  )
}

export default DrawFrom
