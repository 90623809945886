
import { useState, useContext, Fragment } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from "axios";
import { BsFillPhoneFill } from "react-icons/bs";

import {
  Card,
  CardBody,
  Form,
  Input,
  FormGroup,
  Label,
  CustomInput,
  Button
} from 'reactstrap'

import './login.css';
import BTN_login from '../../img/btn_login.png';


const Login = props => {
  const [phone, setPhone] = useState('')
  const [otp, setOTP] = useState('')
  const history = useHistory()
  const [post, setPost] = useState("")

  const headers = {
    "Content-Type": "application/json",
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'POST',
  };

  const onLogin = () => {

    const requestModel = {
      phone_Number: phone,
      otp: otp,
    }
    // เช็คว่าได้กรอกข้อมูลมั้ย
    if (phone === "" || otp === "") {
      alert("กรุณากรอก Phone Number หรือ OTP ให้ครบ")
    } else {
      axios.post("https://sw.travel2tips.me/api/login", requestModel, {
        headers
      }).then((response) => {
        setPost(response.data);
        props.history.push({ pathname: '/luck-draw', state: response.data.Phone_Number });

      });

    }

  }


  const sendOTP = () => {
    if (phone === "") {
      alert("กรุณากรอก Phone Number")
    } else {

      axios.get(`https://sw.travel2tips.me/api/sendOTP/${phone}`, {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Credentials': true,
          'Access-Control-Allow-Headers': '*',
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
      }).then((response) => {

      });
    }



  }



  return (
    <div className="Container login">
      <div className="row mt-4">
        <Label></Label>
        <div className="col-12 col-md-6 offset-md-3">
          <div className="form-group buttonIn">

            <input
              type="text"
              pattern="[0-9]*"
              className="field"
              id="exampleInputEmail1"
              placeholder="Phone Number"
              onChange={event => setPhone(event.target.value)}
            />
            <button onClick={() => sendOTP()} >
              OTP
            </button>
          </div>

          <div className="form-group buttonIn">
            <input
              type="text"
              pattern="[0-9]*"
              className="field"
              id="exampleInputPassword1"
              placeholder="OTP"
              placeholderTextColor="#000"
              onChange={event => setOTP(event.target.value)}

            />
          </div>
          <img width="250" height="57" key='Start' src={BTN_login} alt='' onClick={() => onLogin()} />

        </div>
      </div>
    </div>
  )
}

export default Login
