import { Fragment, useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import axios from "axios";
import React from "react";
import { Container, Button, CardBody, Input, Row, Col } from 'reactstrap'
import './draw.css';



import BN_10 from '../../img/bn_10.png';
import BN_30 from '../../img/bn_30.png';
import BN_50 from '../../img/bn_50.png';
import BN_60 from '../../img/bn_60.png';
import BN_99 from '../../img/bn_99.png';
import BN_100 from '../../img/bn_100.png';
import BN_200 from '../../img/bn_200.png';
import BN_300 from '../../img/bn_300.png';
import BN_400 from '../../img/bn_400.png';
import BN_599 from '../../img/bn_599.png';
import BN_1999 from '../../img/bn_1999.png';
import BNT_spin from '../../img/btn_spin.png';

import CH from '../../img/casino-box.png';

import Modal from "../component/Modal";


const Draw = (props) => {
    const location = useLocation();
    const [isSelect, setIsSelect] = useState();
    const [getResultBonus, setResultBonus] = useState(10);
    const [getPosition, setPosition] = useState();

    const [modalShow, setModalShow] = useState(false);
    // Modal open state
    const [modal, setModal] = React.useState(false);

    // Toggle for Modal
    const toggle = () => setModal(!modal);

    const headers = {
        "Content-Type": "application/json",
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
    };



    var timer = null;
    var bReady = true;//Define a lottery switch
    var prize = [0, 1, 2, 3, 5, 7, 11, 10, 9, 8, 6, 4];//The order of prize li label rolling
    var num;//num is used to store the random function obtained, which is the prize drawn

    function startlottery() {

        axios.get(`https://sw.travel2tips.me/api/drawBonus/${location.state}`, {
            headers
        }).then((response) => {
            setPosition(response.data.position);
            setResultBonus(response.data.bonus);
            if (bReady) {
                bReady = false;
                // if (getPosition != undefined) {
                startinit(response.data.position);
                // }

            }
        });

    }

    function startinit(num) {
        var i = 0; //Define an i to calculate the total number of sweeps
        var t = 200; //The running speed of the lottery is initially 200 milliseconds
        var rounds = 3; //Number of turns in the lottery
        var rNum = rounds * 12; //Mark the number of runs (this is a conditional judgment boundary)
        timer = setTimeout(startscroll, t);//Execute the startscroll function every t milliseconds

        var countByClassAtive = document.querySelectorAll('.itemActive');

        if (countByClassAtive.length > 0) {
            countByClassAtive[0].className = 'item';
        }


        var countByClass = document.querySelectorAll('.item');
        //Raffle scroll function
        function startscroll() {
            //Every time the rolling lottery draws all li classes are set to empty
            for (var j = 0; j < countByClass.length; j++) {

                // var className = countByClass[j].className.split(" ");
                // className[j] = 'item';
                // var resultClass = className[0] + " " + className[1];
                // countByClass[j].className = resultClass;
                countByClass[j].className = 'item';


            }

            var prizenum = prize[i % countByClass.length]; //Get the number in the prize array at the moment through the remaining 8 of i, which is the position where the mask mark appears
            // var className = countByClass[prizenum].className.split(" ");
            // className[prizenum] = 'itemActive';
            // var resultClass = className[0] + " " + className[1];
            // countByClass[prizenum].className = resultClass;
            countByClass[prizenum].className = 'itemActive';

            i++;

            if (i < rNum - 12) { //When i is less than the number of revolutions (rNum-8 times), t remains unchanged or 200 milliseconds
                timer = setTimeout(startscroll, t);//Continue to execute the lottery scroll
            } else if (i >= rNum - 12 && i < rNum + num) {
                //t time becomes longer, at this time the timer running speed is reduced, and the label refresh speed is also reduced
                t += (i - rNum + 12) * 5;
                timer = setTimeout(startscroll, t);//Continue to execute the lottery scroll
            }

            if (i >= rNum + num) {//When i is greater than rNum plus random number num times, the timer ends, and a prompt box appears to prompt the winning information

                if (num == 0) {
                    toggle();
                } else if (num == 1) {
                    toggle();

                } else if (num == 2) {
                    toggle();

                } else if (num == 3) {
                    toggle();

                } else if (num == 4) {
                    toggle();


                } else if (num == 5) {
                    toggle();

                } else if (num == 6) {
                    toggle();

                } else if (num == 7) {
                    toggle();
                } else if (num == 8) {
                    toggle();
                } else if (num == 9) {
                    toggle();
                } else if (num == 10) {
                    toggle();
                } else if (num == 11) {
                    toggle();
                } else if (num == 12) {
                    toggle();
                }

                var timer2 = null;
                timer2 = setTimeout(function () {
                    clearTimeout(timer2);
                }, 1000);
                bReady = true;//When the timer expires, let the span tag become a lottery state
                clearTimeout(timer);
            }
        }

    }



    return (

        <div class="draw">
            <marquee
                behavior='scroll'
                width='50%'
                direction='left'
                height='50px'
            >Test</marquee>

            <div class="drawBox ">
                <div class="GridContainer peding">
                    <div class="item"><img width="70" height="70" src={BN_99} alt='' /></div>
                    <div class="item"><img width="70" height="70" src={BN_10} alt='' /></div>
                    <div class="item"><img width="70" height="70" src={BN_50} alt='' /></div>
                    <div class="item"> <img width="70" height="70" src={BN_400} alt='' /></div>
                    <div class="item" > <img width="70" height="70" src={BN_10} alt='' /></div>

                    {/* <div class="item1 item6"><img src={BNT_spin} alt='' onClick={() => startlottery()} /></div> */}
                    <div class="item1 item6"><img src={BNT_spin} alt='' onClick={toggle} /></div>

                    <div class="item"><img width="70" height="70" src={BN_30} alt='' /></div>
                    <div class="item"> <img width="70" height="70" src={BN_60} alt='' /></div>
                    <div class="item"><img width="70" height="70" src={BN_300} alt='' /></div>
                    <div class="item"> <img width="70" height="70" src={BN_1999} alt='' /></div>
                    <div class="item"> <img width="70" height="70" src={BN_100} alt='' /></div>
                    <div class="item"> <img width="70" height="70" src={BN_200} alt='' /></div>
                    <div class="item"> <img width="70" height="70" src={BN_599} alt='' /></div>
                </div >

            </div>
            {
                (modal) ? <div class='modal_overlay'></div> : null
            }
            <Modal onClose={toggle} show={modal} bonus={getResultBonus}>

            </Modal>
        </div>
    )

}


export default Draw