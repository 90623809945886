


export const columns = [
   
    {
        name: 'Phone number',
        minWidth: '100px',
        cell: row => <span>{row.Phone_Number}</span>
    },
    {
        name: 'Bonus',
        minWidth: '150px',
        cell: row => <span >{row.Bonus}</span>
    },
   

]
