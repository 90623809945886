// ** Router Import
import Router from './router/Router'
import './App.css';


function App() {
  return (

    <div className="App">
      <div className="App-BG">
        <Router />
      </div>

    </div>


  );

}

export default App
