import { BrowserRouter as AppRouter, Route, Switch, Redirect } from 'react-router-dom'
import Admin from '../view/admin'
import DrawFrom from '../view/draw'
import Login from '../view/login/login'
import { DefaultRoute, Routes } from './routes'


const Router = () => {

    return (
        <AppRouter >
            <Route exact path="/" component={Login} />
            <Route exact path="/luck-draw" component={DrawFrom} />
            <Route exact path="/admin" component={Admin} />

        </AppRouter>
    )
}

export default Router