import React from "react";
import { useState } from 'react'
import DataTable from 'react-data-table-component'
import { columns } from './columns'
import axios from "axios";

const Admin = (props) => {

    const headers = {
        "Content-Type": "application/json",
        'Access-Control-Allow-Credentials': true,
        'Access-Control-Allow-Headers': '*',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'POST',
    };


    const flitter = () => {
        // const [getData, setData] = useState();

        const requestModel = {
            phone_Number: "",
            otp: '',
        }

        axios.post("https://sw.travel2tips.me/api/flitter", requestModel, {
            headers
        }).then((response) => {
            return response.data;

        });
    }


    return (
        <div>
            <DataTable
                noHeader
                pagination
                subHeader
                responsive
                paginationServer
                highlightOnHover
                columns={columns}
                className='react-dataTable'
                data={flitter()}

            />
        </div>

    )

}


export default Admin